import React, { useState, useRef } from "react";
import { Button, Card, Form } from "react-bootstrap";
import emailjs from '@emailjs/browser';

function ContactCard() {
  const [senderEmail, setSenderEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);

  const handleSenderEmailChange = (e) => {
    setSenderEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_nw9ai36', 'template_qpl3kkm', form.current, {
        publicKey: '7GI2OpufqKBIDwABg',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSent(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setIsSent(false);
        },
      );
  };

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isFormValid = () => {
    return emailIsValid(senderEmail) && message.trim() !== '';
  };

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", marginBottom: "40px" }}>
          We welcome you to connect with us for a complimentary <span className="purple"> 1 hour consulting </span> session, dedicated to understanding your requirements. Together, we'll chart a path towards the optimal solution tailored to your needs, with <span className="purple"> no obligations </span> attached.
          </p>

          {!isSent ? (
            <div className="message-border">
              <Form onSubmit={handleSubmit} ref={form}>
              <Form.Label style={{ marginBottom: "20px", textTransform: "uppercase", fontWeight: "bold" }}>Drop us a message</Form.Label>
                <Form.Group controlId="name" style={{ marginBottom: "20px" }}>
                <Form.Control
                  type="email"
                  name="from_name"
                  required
                  placeholder="What's your email address?"
                  value={senderEmail}
                  onChange={handleSenderEmailChange}
                  style={{ backgroundColor: "transparent", borderColor:"transparent", color: "#fff" }}
                  className="placeholder-color input-field"
                />
                {!emailIsValid(senderEmail) && senderEmail !== '' && (
                  <p style={{ color: 'red', marginTop: '5px', fontSize: "12px" }}>E-mail is not valid, please enter valid e-mail address!</p>
                )}
              </Form.Group>
              <Form.Group controlId="message" style={{ marginBottom: "20px" }}>
                <Form.Control
                  as="textarea"
                  name="message"
                  required
                  rows={5}
                  placeholder="Tell us about your thoughts..."
                  value={message}
                  onChange={handleMessageChange}
                  style={{ backgroundColor: "transparent", borderColor:"transparent",  color: "#fff" }}
                  className="placeholder-color input-field"
                />
              </Form.Group>
              <Button type="submit" variant="primary" className="submit-button" disabled={!isFormValid()}>Send message</Button>
            </Form>
          </div>
          ) : (
            <p style={{ textAlign: "center", color: "#0fb3c9", marginTop: "50px", fontWeight: "bold" }}>
              Thank you for your message, we'll reply as soon as possible!
            </p>
          )}

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default ContactCard;
