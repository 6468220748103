import React from "react";
import Card from "react-bootstrap/Card";
import { FaStar } from "react-icons/fa";

function AboutCard() {
  return (
    <Card className="quote-card-view" style={{ marginTop: "40px" }}>
      <Card.Body style={{ padding: 0 }}>
        <blockquote className="blockquote mb-0">
            <p className="paragraph-wrapper">
              <p style={{ textAlign: "justify" }}>
              The acronym "<span className="purple">POA</span>" denotes "<span className="purple">Good</span>" in the Swahili language.
              <br />
              We opted for a simplistic representation that mirrors our approach to <span className="purple">software development</span>, as often, the most straightforward solutions prove to be the most effective.
              </p>

              <br />
              <br />

              <p style={{ textAlign: "justify" }}>
              It is a <b className="purple">United Kingdom</b> based company founded by Peter Kaposi.
              Peter with
              <i>
                <b className="purple"> 7+ years </b>
              </i>
              of experience in
              <i>
                <b className="purple"> IT </b>
              </i>
              and programming, has spent the last
              <i>
                <b className="purple"> 4+ years </b>
              </i>
              specializing in the
              <i>
                <b className="purple"> development </b>
              </i>
              of
              <i>
                <b className="purple"> web </b>
              </i> and 
              <i>
                <b className="purple"> mobile </b>
              </i> applications.
              </p>

              <br />
              <br />

              <p style={{ textAlign: "justify" }}>
              <h4 style={{ fontWeight: 'bold' }}><span className="purple">POA </span> Software Ltd. offers two <span className="purple"> service models</span></h4>
              <br />
              <ul className="fancy-bullet-points">
                <li><FaStar className="bullet-icon" />
                <span className="purple" style={{ fontWeight: 'bold' }}>Individual Contractor Placement</span>: Integrate skilled professionals into your company, whether for ongoing or new projects.
                </li>
                <br />
                <li><FaStar className="bullet-icon" />
                <span className="purple" style={{ fontWeight: 'bold' }}>End-to-End Project Delivery</span>: Our dedicated team take charge of your entire project lifecycle, from conception to delivery, tailored to your specific requirements.
                </li>
              </ul>
              </p>

            </p> 
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
