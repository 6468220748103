import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import Particle from "../Particle";
import myImg from "../../Assets/avatar.png";
import ContactCard from './ContactCard';
import { MdOutlineAlternateEmail } from "react-icons/md";
import { BiMapPin } from "react-icons/bi";
import { HiOutlinePhone } from "react-icons/hi";
import pdf from "../../Assets/../Assets/Peter_Kaposi_CV_2024.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Contact() {
  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1rem", textTransform: "uppercase", fontWeight: "bold", paddingBottom: "20px" }}>
              Contact <strong className="purple">US</strong>
            </h1>
            <ContactCard />
          </Col>
          <Col md={5}>
            <Tilt>
            <img
              src={myImg}
              alt="contact"
              className="img-fluid contact-image"
              style={{ borderRadius: 30 }}
            />
            </Tilt>
            <div className="contact-info" style={{ marginTop: "50px" }}>
              <ul>
              <li className="about-activity">
                <MdOutlineAlternateEmail />{" "}
                <span className="purple">E-mail: </span>
                peter.kaposi@poasoftware.com
              </li>
              <li className="about-activity">
                <HiOutlinePhone /> <span className="purple">Phone: </span>
                +36 70 299 7299
              </li>
              <li className="about-activity">
                <BiMapPin /> <span className="purple">Address: </span>
                Hova House, 1 Hova Villas, <br />Brighton & Hove, England, BN3 3DH
              </li>
            </ul>
          </div>
          <Row style={{ justifyContent: "center", position: "relative", marginTop: "40px" }}>
              <Button
                variant="primary"
                href={pdf}
                target="_blank"
                style={{ maxWidth: "200px" }}
              >
              <AiOutlineDownload />
              &nbsp;Download CV
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contact;
