import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import poaLogo from "../../Assets/logo.png";
import Particle from "../Particle";
import Type from "./Type";
import { FaLaptopCode, FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { GiBrain } from "react-icons/gi";
import { TfiRulerAlt2 } from "react-icons/tfi";
import { SiTestcafe } from "react-icons/si";
import { IoIosRocket } from "react-icons/io";
import { FaGear } from "react-icons/fa6";

function Home() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={7} className="pt-3 pb-5">
            <h1 style={{ fontSize: "2.1rem", textTransform: "uppercase", fontWeight: "bold", marginBottom: 40 }}>
              Our <span className="purple"> Services </span>
            </h1>
            <div className="type-wrapper">
              <Type />
            </div>
            <Container fluid style={{ textAlign: '-webkit-center' }}>
              <Col md={8} className="home-about-description">
                <h2 className="service-model-title">
                    <span className="purple">Industry</span> Experience
                </h2>
                <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                  <li><h4>Automotive</h4></li>
                  <li><h4>Green Energy</h4></li>
                  <li><h4>Healthcare Solutions</h4></li>
                  <li><h4>Tools and Equipment Sector</h4></li>
                  <li><h4>Sport-Advertisement Technology</h4></li>
                </ul>
              </Col>
              <Col md={8} className="home-about-description" style={{ marginBottom: 0 }}>
                <h2 className="service-model-title">
                  <span className="purple"> service </span> models
                </h2>
                <Row style={{ justifyContent: 'space-between'}}>
                  <Col md={6} style={{ marginBottom: 40 }}>
                    <h4>
                      <span className="purple">Contractor Placement</span>
                      <br />
                      <br /> Integrate skilled professionals into
                      your company, whether for ongoing or new projects.
                    </h4>
                  </Col>
                  <Col md={6} style={{ marginBottom: 20 }}>
                    <h4>
                      <span className="purple">
                        End-to-End Project Delivery
                      </span>
                      <br />
                      <br /> Our dedicated team take charge of your
                      entire project lifecycle, from conception to delivery,
                      tailored to your specific requirements.
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Container>
          </Col>
          <Col md={5} className="pt-3 pb-5" style={{ textAlign: '-webkit-center' }}>
            <Tilt>
              <img
                src={poaLogo}
                alt="logo"
                className="img-fluid poa-logo"
                style={{ maxHeight: "200px", marginBottom: 40 }}
              />
            </Tilt>
            <Col md={7}>
            <div className="timeline">
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <IoDocumentText />
                  </div>
                  <div className="timeline-content">Requirements</div>
                  <div className="timeline-tooltip">
                    Define the functional and non-functional requirements of the
                    software project.
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <GiBrain />
                  </div>
                  <div className="timeline-content">Planning</div>
                  <div className="timeline-tooltip">
                    Create a roadmap outlining the tasks, timelines, and
                    resources needed to complete the project successfully.
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <TfiRulerAlt2 />
                  </div>
                  <div className="timeline-content">Design</div>
                  <div className="timeline-tooltip">
                    Design the architecture, user interface, and system
                    components of the software application.
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <FaLaptopCode />
                  </div>
                  <div className="timeline-content">Development</div>
                  <div className="timeline-tooltip">
                    Write, review, and test the source code that implements the
                    software's functionality.
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <SiTestcafe />
                  </div>
                  <div className="timeline-content">Testing</div>
                  <div className="timeline-tooltip">
                    Execute various test cases to ensure the software meets the
                    specified requirements and is free from defects.
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <IoIosRocket />
                  </div>
                  <div className="timeline-content">Deploy</div>
                  <div className="timeline-tooltip">
                    Prepare the software for deployment to production or testing
                    environments.
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">
                    <FaGear />
                  </div>
                  <div className="timeline-content">Maintenance</div>
                  <div className="timeline-tooltip">
                    Provide ongoing support, updates, and maintenance to keep
                    the software running smoothly.
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>
              FIND <span className="purple"> US </span>
            </h1>
            <p>
              Feel free to <span className="purple">connect </span> on social
              media.
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/poasoftwareltd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/poa-software-ltd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home;