/* eslint-disable no-unused-vars */
import React from "react";
import { Card, Button } from "react-bootstrap";
import { CgWebsite } from "react-icons/cg";
import { DiApple } from "react-icons/di";
import { ImAndroid } from "react-icons/im";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        {/* <Card.Title>{props.title}</Card.Title> */}
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description2}
        </Card.Text>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.techStack}
        </Card.Text>
      </Card.Body>
      {/* <Card.Footer style={{ marginBottom: "16px" }}>
        {props.iosLink && props.androidLink && (
          <React.Fragment>
            <Button
              variant="primary"
              href={props.iosLink}
              target="_blank"
              style={{ margin: "8px", width: 225 }}
            >
              <DiApple /> Download IOS App
            </Button>
            <Button
              variant="primary"
              href={props.androidLink}
              target="_blank"
              style={{ margin: "8px", width: 225 }}
            >
              <ImAndroid /> Download Android App
            </Button>
          </React.Fragment>
        )}
        <Button
          variant="primary"
          href={props.ghLink}
          target="_blank"
          style={{ margin: "8px", width: 225 }}
        >
          <CgWebsite /> Navigate to the website
        </Button>
      </Card.Footer> */}
    </Card>
  );
}
export default ProjectCards;
