import React, { useState, useEffect } from "react";

function Type() {
  const [index, setIndex] = useState(0);
  const strings = [
    "Web Development",
    "Custom Software Development",
    "Mobile Application Development",
    "E-commerce Solutions",
    "API Development & Integration",
    "Backend Arhitecture",
    "Frontend Arhitecture",
    "Cloud Services",
    "UI/UX Design",
    "Quality Assurance & Testing",
    "Maintenance & Support",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % strings.length);
    }, 1500);
    return () => clearInterval(interval);
  });

  return strings.map((string, i) => {
        const words = string.split(' ');
        return (
          <h1
            key={i}
            className={`typewriter-text ${i === index ? "appear" : ""}`}
          >
            <span className="purple">{words[0]}</span> {words.slice(1).join(' ')}
          </h1>
        );
      })
}

export default Type;