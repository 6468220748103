import React from 'react';
import { Card, Image } from 'react-bootstrap';

const PartnerCard = ({ partnerURL, logoSrc, width, height, color }) => {
  const imageStyles = {
    width: `${width}px`,
    height: `${height || 'auto'}`,
    backgroundColor: `${color || 'white'}`,
    cursor: 'pointer',
  };

  return (
    <Card className="partner-card" style={{ backgroundColor: 'transparent'}}>
      {partnerURL ? (
        <a href={partnerURL} target="_blank" rel="noopener noreferrer">
          <Image src={logoSrc} style={imageStyles} />
        </a>
      ) : (
        <Image src={logoSrc} style={imageStyles} />
      )}
    </Card>
  );
};

export default PartnerCard;