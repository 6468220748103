import React from "react";
import Slider from "react-slick";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import PartnerCard from "./PartnerCard";
import Particle from "../Particle";
import jsonData from "../../data/data.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Projects() {
  const { projects, partners } = jsonData;

  const sliderConfig = {
    slidesToShow: 3,
    infinite: false,
    looped: false,
    variableWidth: false,
    centerMode: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading" style={{ fontSize: "2.1rem", textTransform: "uppercase", fontWeight: "bold" }}>
          Our <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects we've worked on recently.
        </p>
        <Slider {...sliderConfig}>
          {projects.map((project, index) => (
            <div key={index} className="slider-item">
              <Col className="project-card">
                <ProjectCard {...project} />
              </Col>
            </div>
          ))}
        </Slider>
      </Container>
      <Container>
        <h1 className="project-heading partners" style={{ fontSize: "2.1rem", textTransform: "uppercase", fontWeight: "bold" }}>
          Our <strong className="purple">Partners </strong>
        </h1>
        <p style={{ color: "white" }}>
          Our partners with whom we cooperated in the above-mentioned projects.
        </p>
        <Row style={{ justifyContent: "center", alignItems: "center", paddingTop: "30px", }}>
          {partners.map((partner, index) => (
            <OverlayTrigger key={index} overlay={<Tooltip>{partner.tooltip}</Tooltip>}>
              <Col md={2} style={{ paddingBottom: "50px" }}>
                <PartnerCard {...partner} />
              </Col>
            </OverlayTrigger>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
